<template>
  <div class="links">
    <home-header></home-header>
    <div class="main">
      <div class="title">{{$t('web.links')}}</div>
      <div class="subtitle">
        このサイトには第三者によるFluenDayアプリに関するレビュー記事やリリースへのリンクを掲載しています。
        掲載されている情報は、発表日現在の情報です。下記の情報につきましては、予告なしに変更される場合がございますので、あらかじめご了承ください。
        ご質問等がございましたら、support@fluenday.comまでお問い合わせください。
      </div>
      <div class="linkList">
        <template v-for="(link, i) in linkList">
          <div class="item" :key="i">
            <div class="date">{{link.date}}</div>
            <a class="link" :href="link.url" target="_blank">{{link.name}}</a>
          </div>
        </template>
      </div>
    </div>
    <home-footer class="linksFooter"></home-footer>
  </div>
</template>

<script>
  import Header from '@/components/Header.vue'
  import Footer from '@/components/Footer.vue'

  export default {
    data() {
      return {
        linkList: [
          {
            name: 'アプリ紹介メディア「Appliv」によるレビュー記事',
            url: 'https://app-liv.jp/5344507/#google_vignette',
            date: '2023.07.20'
          },
          {
            name: '海外旅行や趣味を紹介するメディア「YOKKA」によるレビュー記事',
            url: 'https://www.veltra.com/jp/yokka/article/english-app-fluenday-review/',
            date: '2023.08.25'
          }
        ]
      }
    },
    components: {
      'home-header': Header,
      'home-footer': Footer
    }
  }
</script>

<style lang="scss" scoped>
  .links {
    background: #FCFCFF;
    .main {
      padding-top: 80px;
      width: 100%;
      min-height: calc(100vh - 300px);
      .title {
        margin-top: 40px;
        font-size: 30px;
        text-align: center;
      }
      .subtitle {
        margin: auto;
        margin-top: 40px;
        font-size: 16px;
        line-height: 26px;
        max-width: 1100px;
      }
      .linkList {
        margin: auto;
        margin-top: 40px;
        max-width: 1100px;
        text-align: center;
        .item {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-size: 16px;
          font-weight: bold;
          padding: 10px;
          border-bottom: 1px solid #ccc;
          text-align: left;
          .date {
            width: 150px;
          }
          .link {
            color: #004E94;
          }
        }
      }
    }
    .linksFooter {
      overflow: auto;
    }
  }

  @media screen and (max-width: 768px) {
    .links {
      .main {
        margin: 0 auto;
        width: 90%;
        .linkList {
          .item {
            flex-direction: column;
            align-items: flex-start;
            .date {
              width: unset;
            }
            .link {
              margin-top: 6px;
            }
          }
        }
      }
    }
  }
</style>